module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/usr/src/app/src/translations","languages":["de","en"],"defaultLanguage":"de","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.freshcells.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"freshcells","short_name":"freshcells","start_url":"/","background_color":"#04589A","theme_color":"#04589A","display":"minimal-ui","icon":"./src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f1f4524e778bcf2a0e83d49fde8fe254"},
    },{
      plugin: require('../plugins/gatsby-plugin-cookie-consent/gatsby-browser.js'),
      options: {"plugins":[],"cookieHubId":"19b85b02","googleTagManagerId":"GTM-KSV925T"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
